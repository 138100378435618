<script>
  

  import useVuelidate from "@vuelidate/core";
  import { mapActions} from "vuex";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';

  //import userSearchModelWS  from "@/components/widgets/worksites/userSearchModelWS";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'ordiniEffettuati',
    props: [
      'type',
      'projectID',
      'worksiteID',
      'taskID',
      'interventionID',
      'data',
    ],
    setup() {
      moment.locale('it')
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Gestione Prodotti progetto",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          name:"",
          details:"",
          ownership:false,
          ownershipDesc:'',
          manufacturer:'',
          manufacturerDesc:'',
          kmStart:'',
          createdBy:this.$store.state.auth.currentUser,
        },
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        n_ordersResults:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        pageOrders: 1,
        perPageOrders: 10,
        responseOrdersList: [
        ],
        
        select:'all',
        search:'',
        defaultOptions: {
          animationData: []
        },
        defaultOptions1: {
          animationData: []
        },
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:new Date().getFullYear().toString() + '-12-31',
        resultsList:[],
        teamLeaderID:'',
        qSearch:'',
        dataEdit:{},
        
      };
    },
    components: {
   
    },

    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      
    },

    methods: {
      ...mapActions('ecommercesettings', ['addItemToOrder', 'getCartByOrd']),
      convertDatetime(date){
        if (date){
          return  moment.unix(date).format('DD/MM/YYYY HH:MM') 
        }
      },

      len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      async fullSearch(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}intervention-reports/search/products/fulltext`,
            { 
              params: { 
                q: this.qSearch,
              },
              headers: { 'authorization':this.tk} 
            }
        )
        .then((response) => {
            if (response.data)  {
            this.resultsList = response.data
            }
        })
      },
      getList(){
       
        let urlRef= `items-by-projectid/${this.projectID}`
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}projects/${urlRef}`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseList = response.data.results.data;
          this.n_results = response.data.n_results;
          this.paginated = response.data.results
          this.getProcCarts()
          })
      },
      getProcCarts(){
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}orders/carts/status`, 
          { 
            id: this.projectID,
            status: 'processing',
            perPage: this.perPage,
            page: this.page,
          }, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
         
          this.responseOrdersList = response.data.results.data;
          let rows = response.data.results.data.filter(function(el) {
            return el.doc_type == 'row'
          });
          let n = 0
          for (let i of rows) {
            if (i.detail_item.qta) {
              n+=i.detail_item.qta
            }
          }

          if (rows) {
            this.n_ordersResults = n
          } else {
            this.n_ordersResults = 0
          }
          
          this.paginated =  response.data.results
          })

      },
      editdata(data) {
        this.dataEdit = data
      },
      initItem(){
        this.submitted = true;
        this.v$.$touch()
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}equipments/register`, this.item, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            if (response.data) {
              Swal.fire({  
                title: this.$t("t-msg-vehicle-init") , 
                text: this.$t("t-msg-vehicle-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              }).then(()=>{
                  document.getElementById("closemodalEquip").click();
              });
            }
        })
      },
      addElement(params){
        let date = moment().format('YYYY/MM/DD')
        params.projectID = this.projectID
        params.productID = params._id
        
        params.area = 'project'
        params.year = date.split('/')[0]
        params.day = date.split('/')[2]
        params.month = date.split('/')[1]
        params.number = 1
        /*
        if ( moment().day() <10 ){
            params.day =`0${moment().day()}`
        } else {
            params.day = moment().month()
        }
        */
        params.date_tmp = moment().unix()
        params.createdBy = this.datastore.auth.currentUser
        if (params._id){
          delete params._id
        }

        this.axiosInterceptor()
        axios.post(`${this.UrlServer}intervention-reports/add-product`, params, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
             // let timerInterval;
              console.log(percentUploaded)
              /*
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
                
              }).then((result) => {
                
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });
              */

            },
        } )
          .then((response)=>{
            if (response.data) {
              this.getList()
              this.resultsList = []
              this.search=''
              Swal.fire({  
                title: this.$t("t-msg-element") , 
                text: this.$t("t-msg-element-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              })
            }
        })
      },
      deletedata(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}intervention-reports/worksite-item/${id}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getList() 
              })
            }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(responseList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return responseList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      paginatedOrders(response) {
        let page = this.pageOrders;
        let perPage = this.perPageOrders;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return response.slice(from, to);
      },
      paginateOrdersAct(pg){
        this.pageOrders = pg
        this.getProcCarts()
      },
      splitStr(str,car){
            if (str){
            if(str.includes(car)==true) {
                let val =  str.split(car)
                return `${val[0]} ${val[1]}`
            } else{
                return str
            }
            } else {
            return ''
            }
      },
      selectItem(params){
          let array = this.responseList.filter(function(el){
            return el._id == params._id
          })
          if (array.length==0){
            this.addElement(params)
          } else {
            Swal.fire({
              title:"Prodotto già presente",
              text:"Prodotto che hai selezionato è già presente!",
              icon:"info",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }) 
          }
      },
      updatedata(){
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}intervention-reports/worksite-item/${this.dataEdit._id}`, this.dataEdit, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
            } )
            .then((response)=>{
              if (response.data) {
                document.getElementById("editclosemodalEquip").click();
              }
          })
      },
      getCart(){
        if ( localStorage.getItem("orx") ) {
          this.getCartByOrd(localStorage.getItem("orx"))
        }
      },
      addTC(gcode, codMarca, qty){
        moment.locale('it')
        let momentCode = moment().unix()
        let orx = false
      
        if ( localStorage.getItem("orxpro") ) {
          orx = localStorage.getItem("orxpro")
        } else {
          localStorage.setItem("orxpro", orx)
        }
        let sr = 'mov-magazzino'

        let data = { 
          productCode: gcode,
          userId: this.data.customerDetails._id,
          projectID: this.data._id,
          cuuid:momentCode,
          orx:orx,
          salesRoute:sr,
          qta: qty,
        }
        this.addItemToOrder(data)
      },
    },
    mounted() {
      if (this.data) {
        if(!this.data.teamLeaderID){
          this.teamLeaderID = ''
        } else {
          this.teamLeaderID = this.data.teamLeaderID
        }
      }
      this.getProcCarts()
    }
  };
</script>

<template>
      <div class="card">
        <div class="card-header back-magenta-light">
            <h5 class="card-title mb-0 bold-white"> <i class="mdi mdi-warehouse"></i> Consegne pianificate</h5>
        </div>
        <div class="card-body">
          <div class="card-header  mb-50">
            <div class="row align-items-center mb-25">
              <div class="col ">
                <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">

                  <li class="nav-item">
                    <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                       Lista
                      <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_ordersResults}}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-auto">
                <div id="selection-element">
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-card mb-1">
              <div class="row thRow  ">
                <div class="col text-left">
                  Descrizione
                </div>
                <div class="col text-left">
                  Codice
                </div>
                <div class="col text-left">
                  Quantità ordinata
                </div>
                <div class="col text-left">
                  Data
                </div>
                <div class="col text-left">
                  Reg. SW Contabilità 
                </div>
              </div>
              
              <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of responseOrdersList" :key="index" >
            
                <div class="col text-left" v-if="item.doc_type=='row'">
                  <strong>{{ item.detail_item.Nome}}</strong>
                  <br><i>{{ item.CodMarca }}</i>
                </div>
                <div class="col text-left" v-if="item.doc_type=='row'">
                  {{ item.gcode }}
                </div>
                <div class="col text-left" v-if="item.doc_type=='row'">
                  {{ item.detail_item.qta  }}
                </div>
                <div class="col" v-if="item.doc_type=='row'">
                 
                  <template v-if="item.w_orders">
                    <template v-if="item.w_orders.length>0">
                      {{ convertDatetime(item.w_orders[0].start_tmp) }}
                    </template>
                  </template>
                </div>
                <div class="col" v-if="item.doc_type=='row'">

                </div>
              </div>
            </div>
                <div>
                  <div class="table-responsive table-card mb-1">

                    <div class="noresult" style="display: none">
                      <div class="text-center">
                        <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                          :height="75" :width="75" />
                        <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                        <p class="text-muted mb-0">
                        
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex tRow justify-content-end mt-3" >
                        <div class="col-lg-2 col-md-12 text-right">
                            <label class="pt-2">Risultati per pagina:</label>
                        </div>        
                        <div class="col-lg-2 col-md-12 ">
                            <div class="pl-10 pr-20">
                                <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                    <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 ">
                            <div class="pagination-wrap hstack gap-2">
                            <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                                Precedenti
                            </a>
                            <ul class="pagination listjs-pagination mb-5 pr-10 pl-10" >
                                <li :class="{
                                        active: index == page,
                                        disabled: index == '...',
                                        }" v-for="index in paginated.total_pages" :key="index">
                                    <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                                </li>
                            
                            </ul>
                            <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                            Successivi
                            </a>
                            </div>
                        </div>
                  </div>
            </div>
        </div>

        </div>
      </div>

</template>